import { PillTypes } from 'src/components/atoms/Pill/Pill';

export enum CheckStatusEnum {
  'PENDING' = 'PENDING',
  'APPROVED' = 'APPROVED',
  'UNAPPROVED' = 'UNAPPROVED',
  'WARNING' = 'WARNING',
  'CANCELLED' = 'CANCELLED',
  'CANCELLED_NOT_BILLABLE' = 'CANCELLED_NOT_BILLABLE',
  'APPROVED_WITH_GUARANTOR' = 'APPROVED_WITH_GUARANTOR',
  'APPROVED_WITH_ADVANCE_PAYMENT' = 'APPROVED_WITH_ADVANCE_PAYMENT',
  'SUBMITTED' = 'SUBMITTED',
  'ON_HOLD' = 'ON_HOLD',
}

export enum DecisionString {
  INCONCLUSIVE = 'Inconclusive',
  CONSIDER = 'Consider',
  ACCEPTABLE = 'Acceptable',
  HIGH_RISK = 'High Risk',
}

export type IndicatorPillTypes = Extract<
  PillTypes,
  PillTypes.SUCCESS | PillTypes.WARNING | PillTypes.INCONCLUSIVE
>;

export type DocumentSide = 'FRONT' | 'BACK';

export enum RiskIndicatorString {
  NA = 'N/A',
  CLEAR = 'Clear',
  CONSIDER = 'Consider',
}

export const RiskIndicatorStringToPillType: Record<string, IndicatorPillTypes> = {
  [RiskIndicatorString.NA]: PillTypes.INCONCLUSIVE,
  [RiskIndicatorString.CLEAR]: PillTypes.SUCCESS,
  [RiskIndicatorString.CONSIDER]: PillTypes.WARNING,
};

export type DocResult = {
  tags: string[];
  path: string;
  filename: string;
  font_analysis: string | null;
  total_versions: number | null;
  ela_results: [
    {
      decision: RiskIndicatorString;
      difference_image: null | string;
    },
  ];
  metadata?: {
    creation_dtm: null | string;
    modification_dtm: null | string;
    author: null | string;
    software: null | string;
    has_layers: boolean;
    number_of_fonts: number;
    extracted_text: string | null;
  };
  fdt_summary?: {
    digital_tampering: RiskIndicatorString;
    text_validation: RiskIndicatorString;
    data_consistency: RiskIndicatorString;
    software_integrity: RiskIndicatorString;
    author_validation: RiskIndicatorString;
    embedded_layering: RiskIndicatorString;
  };
  barcode_results?: [
    {
      uuid: null | string;
      document: string | null;
      created_at: string | null;
      extracted_image: string | null;
      code_type: string | null;
      quality: string | null;
      decoded_data: string | null;
    },
  ];
  decision_results: {
    authenticity_check: DecisionString;
    ela: DecisionString;
    overall: DecisionString;
    qr_analysis: DecisionString;
    versions: DecisionString;
    scan: DecisionString;
    font_consistency: DecisionString;
  };
  pdf_version_comparisons: {
    id: string;
    document: string;
    diff: string;
    name: string;
  }[];
  type: string;
};

export type IdDocResult = {
  decision_results: {
    overall: DecisionString;
  };
  filename: string;
  path: string;
  summary: {
    age_validation: string;
    compromised_document: string;
    data_consistency: string;
    data_validation: string;
    image_integrity: string;
    visual_authenticity: string;
  };
  checkType: string;
  side: string;
  type: string;
  backPath?: string;
  backFilename?: string;
};

export type IdSummary = {
  filename: string;
  path: string;
  result: string;
  status: string;
  type: string;
  side: string;
  summary: {
    age_validation: string;
    compromised_document: string;
    data_consistency: string;
    data_validation: string;
    image_integrity: string;
    visual_authenticity: string;
  };
};

export enum TransactionCategory {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export type Transaction = {
  amount: number;
  description: string;
  running_balance: {
    amount: number;
  };
  timestamp: string;
  transaction_category: TransactionCategory;
  transaction_classification: Array<any>;
  transaction_id: string;
  transaction_type: TransactionCategory;
};

export type BankAccounts = {
  status: string;
  results: Array<{
    provider: {
      logo_uri: string;
      provider_id: string;
      display_name: string;
    };
    account_id: string;
    account_type: string;
    display_name: string;
    account_number: {
      number: string;
      sort_code: string;
    };
    update_timestamp: string;
    from_bank_statement: boolean;
  }>;
};

export type WizardlessCheckResults = {
  user: string;
  id_summary: {
    [key: string]: IdSummary;
  };
  metadata: {
    decisions: [
      {
        data: {
          documents: {
            [key: string]: DocResult;
          };
          transactions: {
            accounts: BankAccounts;
            transactions: {
              [key: string]: {
                status: string;
                results: Transaction[];
              };
            }[];
          };
        };
      },
    ];
  };
};

export type SimpleDoc = {
  filename: string;
  decision: DecisionString;
};

export type SummaryData = {
  name: string;
  documents: SimpleDoc[];
};
