import { Disclosure } from '@headlessui/react';
import Image from 'next/image';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import Link from 'next/link';

type Props = {
  title: string;
  navigation: { name: string; href: string }[];
};

const Header = ({ title, navigation }: Props) => {
  const router = useRouter();

  const isCurrent = (href) => {
    return router.pathname.includes(href);
  };

  return (
    <Disclosure data-testid="header" as="div" className="relative bg-sky-700 pb-32 overflow-hidden">
      {({ open }) => (
        <>
          <nav className={clsx('relative z-10 lg:bg-transparent ')}>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
              <div className="relative h-16 flex items-center justify-between">
                <div className="px-2 flex items-center lg:px-0">
                  <div className="flex-shrink-0">
                    <Image
                      width={32}
                      height={32}
                      className="block h-8 w-auto text-white"
                      src="/assets/homeppl-logomark-white.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="hidden lg:block lg:ml-6 lg:space-x-4">
                    <div className="flex space-x-1">
                      {navigation.map((item) => (
                        <Link key={item.name} href={item.href}>
                          <a
                            className={clsx(
                              isCurrent(item.href)
                                ? 'bg-blue-500 text-blue'
                                : 'hover:bg-blue-500 hover:bg-opacity-50',
                              'rounded-md py-2 px-3 text-sm text-blue-200',
                            )}
                          >
                            {item.name}
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          {/* Background triangles */}
          <div
            aria-hidden="true"
            className={clsx(
              open ? 'bottom-0' : 'inset-y-0',
              'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
            )}
          >
            <div className="absolute inset-0 flex">
              <div className="h-full w-1/2 bg-blue-600" />
              <div className="h-full w-1/2 bg-blue-500" />
            </div>
            <div className="relative flex justify-center">
              <svg
                className="flex-shrink-0"
                width={1750}
                height={308}
                viewBox="0 0 1750 308"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                  className="text-blue-600 fill-current"
                />
                <path
                  d="M1465.84 308L16.816 0H1750v308h-284.16z"
                  className="fill-current text-blue-500"
                />
                <path
                  d="M1733.19 0L284.161 308H0V0h1733.19z"
                  className="text-blue-600 fill-current"
                />
                <path
                  d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                  className="text-blue-600 fill-current"
                />
              </svg>
            </div>
          </div>
          {/* Title */}
          <header className="relative py-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-semibold text-white">{title}</h1>
            </div>
          </header>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
