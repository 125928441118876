import clsx from 'clsx';

type Props = {
  type: PillTypes;
  children: React.ReactNode;
  className?: string;
  dot?: boolean;
};

export enum PillTypes {
  NEUTRAL = 'neutral',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INCONCLUSIVE = 'inconclusive',
}
const decisionClasses: Record<PillTypes, string> = {
  [PillTypes.NEUTRAL]: 'bg-blue-200 text-blue-600',
  [PillTypes.SUCCESS]: 'bg-green-200 text-green-800',
  [PillTypes.WARNING]: 'bg-amber-200 text-amber-800',
  [PillTypes.ERROR]: 'bg-red-200 text-red-700',
  [PillTypes.INCONCLUSIVE]: 'bg-grey-200 text-grey-800',
};

const Pill = ({ type, children, className, dot, ...props }: Props) => (
  <div
    data-testid="pill"
    className={clsx(
      dot ? 'rounded-md py-2 h-min' : 'rounded-full py-1',
      'px-3 text-sm font-medium whitespace-nowrap',
      decisionClasses[type],
      className,
    )}
    {...props}
  >
    {dot && (
      <svg
        className="h-1.5 w-1.5 mr-2 inline fill-current"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="25" cy="25" r="25" />
      </svg>
    )}
    {children}
  </div>
);

export default Pill;
