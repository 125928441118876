import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { TranslationsContextProvider } from 'src/renderless/useTranslations';
import AnalyticsProvider from 'src/monitoring/analytics';
import { AppStateContextProvider } from 'src/renderless/useAppState';
import { AuthContextProvider } from 'src/renderless/useAuth';
import Script from 'next/script';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      {process.env.NEXT_PUBLIC_SMARTLOOK_ID && (
        <Script id="smartlook-scrpt" type="text/javascript">
          {`window.smartlook||(function(d) {
    let o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    let c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '${process.env.NEXT_PUBLIC_SMARTLOOK_ID}', { region: 'eu' });`}
        </Script>
      )}
      <TranslationsContextProvider>
        <AuthContextProvider>
          <AnalyticsProvider>
            <AppStateContextProvider>
              <div className="h-screen w-screen">
                <Component {...pageProps} />
              </div>
            </AppStateContextProvider>
          </AnalyticsProvider>
        </AuthContextProvider>
      </TranslationsContextProvider>
    </>
  );
}

export default MyApp;
