import React from 'react';
import clsx from 'clsx';

export type Props = {
  className?: string;
};

const Logo = ({ className, ...props }: Props) => {
  return (
    <div {...props} data-testid="logo" className={clsx('', className)}>
      <svg
        width="117"
        height="24"
        viewBox="0 0 117 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6478 19.597C24.5443 19.4936 24.4912 19.365 24.4912 19.2168V4.67984C24.4912 4.5149 24.5387 4.38351 24.6366 4.27728C24.7344 4.17384 24.863 4.12073 25.028 4.12073H27.0827C27.2476 4.12073 27.379 4.17384 27.4853 4.27728C27.5887 4.38072 27.6418 4.5149 27.6418 4.67984V10.4191H34.1639V4.67984C34.1639 4.5149 34.217 4.38351 34.3204 4.27728C34.4239 4.17384 34.5497 4.12073 34.7006 4.12073H36.7777C36.9427 4.12073 37.0713 4.17384 37.1691 4.27728C37.2642 4.38072 37.3145 4.5149 37.3145 4.67984V19.2168C37.3145 19.365 37.267 19.4936 37.1691 19.597C37.0713 19.7004 36.9427 19.7536 36.7777 19.7536H34.7006C34.5525 19.7536 34.4239 19.7004 34.3204 19.597C34.217 19.4936 34.1639 19.3678 34.1639 19.2168V13.2539H27.6418V19.2168C27.6418 19.365 27.5887 19.4936 27.4853 19.597C27.3818 19.7004 27.2476 19.7536 27.0827 19.7536H25.028C24.8798 19.7536 24.7512 19.7004 24.6478 19.597Z"
          fill="#0E0E0E"
        />
        <path
          d="M47.0665 16.983C47.4915 16.491 47.7179 15.7698 47.7487 14.8165C47.7626 14.6683 47.771 14.3776 47.771 13.947C47.771 13.5165 47.7626 13.2258 47.7487 13.0748C47.7179 12.1215 47.4887 11.3975 47.0554 10.8971C46.622 10.3995 46.021 10.1479 45.2466 10.1479C44.4583 10.1479 43.846 10.3967 43.4155 10.8971C42.9822 11.3947 42.753 12.1215 42.7222 13.0748L42.6999 13.947L42.7222 14.8165C42.753 15.7698 42.9822 16.491 43.4155 16.983C43.846 17.4751 44.4583 17.7211 45.2466 17.7211C46.035 17.7211 46.6416 17.4751 47.0665 16.983ZM41.1819 18.6352C40.2286 17.7406 39.7142 16.505 39.6415 14.9283L39.6191 13.947L39.6415 12.9658C39.717 11.4031 40.237 10.1702 41.2042 9.27006C42.1715 8.36989 43.519 7.9198 45.2466 7.9198C46.9575 7.9198 48.2994 8.36989 49.2666 9.27006C50.2339 10.1702 50.7567 11.4031 50.8294 12.9658C50.8434 13.1447 50.8517 13.4718 50.8517 13.947C50.8517 14.4223 50.8434 14.7522 50.8294 14.9283C50.7539 16.5078 50.2423 17.7406 49.289 18.6352C48.3357 19.5298 46.9883 19.9743 45.2466 19.9743C43.491 19.9771 42.1352 19.5298 41.1819 18.6352Z"
          fill="#0E0E0E"
        />
        <path
          d="M53.1829 19.5969C53.0795 19.4934 53.0264 19.3648 53.0264 19.2167V8.67739C53.0264 8.51245 53.0795 8.38385 53.1829 8.28601C53.2864 8.18816 53.415 8.14064 53.5631 8.14064H55.3271C55.4921 8.14064 55.6207 8.18816 55.7185 8.28601C55.8163 8.38385 55.8639 8.51245 55.8639 8.67739V9.43499C56.669 8.42299 57.7481 7.91699 59.1011 7.91699C60.7533 7.91699 61.9079 8.57954 62.5621 9.90464C62.9199 9.30919 63.4259 8.82835 64.0801 8.46492C64.7342 8.1015 65.4415 7.91699 66.2019 7.91699C67.4236 7.91699 68.4216 8.33353 69.1959 9.16661C69.9703 9.99969 70.3561 11.213 70.3561 12.8064V19.2167C70.3561 19.3648 70.3086 19.4934 70.2107 19.5969C70.1129 19.7003 69.9843 19.7534 69.8194 19.7534H67.9659C67.8177 19.7534 67.6891 19.7003 67.5857 19.5969C67.4823 19.4934 67.4291 19.3676 67.4291 19.2167V12.9854C67.4291 12.0488 67.2307 11.3695 66.8365 10.953C66.4423 10.5364 65.9251 10.3268 65.2849 10.3268C64.7035 10.3268 64.2086 10.5392 63.8005 10.9642C63.3923 11.3891 63.1855 12.0628 63.1855 12.9854V19.2167C63.1855 19.3648 63.1379 19.4934 63.0401 19.5969C62.9423 19.7003 62.8137 19.7534 62.6487 19.7534H60.7729C60.6247 19.7534 60.4961 19.7003 60.3927 19.5969C60.2893 19.4934 60.2361 19.3676 60.2361 19.2167V12.9854C60.2361 12.0628 60.0349 11.3891 59.6323 10.9642C59.2297 10.5392 58.7154 10.3268 58.0919 10.3268C57.5105 10.3268 57.0156 10.5392 56.6075 10.9642C56.1965 11.3891 55.9925 12.0628 55.9925 12.9854V19.2167C55.9925 19.3648 55.9449 19.4934 55.8471 19.5969C55.7493 19.7003 55.6207 19.7534 55.4557 19.7534H53.5575C53.415 19.7534 53.2892 19.7003 53.1829 19.5969Z"
          fill="#0E0E0E"
        />
        <path
          d="M80.4554 12.9183V12.8736C80.4554 11.9958 80.2345 11.2913 79.7956 10.763C79.3567 10.2346 78.7585 9.97181 77.9981 9.97181C77.2405 9.97181 76.6422 10.2374 76.2117 10.763C75.7812 11.2913 75.5631 11.9958 75.5631 12.8736V12.9183H80.4554ZM74.0563 18.5458C73.0807 17.5925 72.5635 16.2395 72.5048 14.4811L72.4824 13.922C72.4824 12.0601 72.9689 10.5952 73.9445 9.52173C74.9202 8.45103 76.2704 7.91428 77.9981 7.91428C79.7705 7.91428 81.1375 8.45103 82.0964 9.52173C83.0581 10.5924 83.5361 12.0238 83.5361 13.8101V14.2798C83.5361 14.428 83.483 14.5565 83.3796 14.66C83.2761 14.7634 83.1419 14.8165 82.977 14.8165H75.5631V14.9955C75.5939 15.8285 75.8203 16.519 76.2453 17.0614C76.6702 17.6037 77.2545 17.8777 77.9981 17.8777C78.8479 17.8777 79.5384 17.5366 80.0752 16.8517C80.2094 16.6868 80.3128 16.5861 80.3883 16.5498C80.4638 16.5134 80.5896 16.4939 80.7685 16.4939H82.7338C82.868 16.4939 82.9826 16.5302 83.0804 16.6057C83.1783 16.6812 83.2258 16.7846 83.2258 16.9188C83.2258 17.2766 83.0133 17.696 82.5884 18.1796C82.1635 18.6632 81.5568 19.0826 80.7685 19.4404C79.9773 19.7982 79.0632 19.9772 78.0204 19.9772C76.3543 19.9772 75.032 19.4991 74.0563 18.5458Z"
          fill="#0E0E0E"
        />
        <path
          d="M94.108 14.618C94.122 14.4698 94.1304 14.2462 94.1304 13.9471C94.1304 13.6507 94.122 13.4243 94.108 13.2761C94.0046 11.3109 93.1547 10.3296 91.5613 10.3296C90.7114 10.3296 90.0796 10.6064 89.6631 11.1571C89.2465 11.7078 89.0229 12.3788 88.9921 13.1671C88.9782 13.346 88.9698 13.6284 88.9698 14.0142C88.9698 14.4028 88.9782 14.6935 88.9921 14.8836C89.0229 15.63 89.2521 16.2618 89.6854 16.7818C90.116 17.3018 90.7422 17.5617 91.5613 17.5617C93.1547 17.5645 94.0046 16.5805 94.108 14.618ZM86.1351 23.8518C86.0316 23.7539 85.9785 23.6253 85.9785 23.4604V8.67743C85.9785 8.51249 86.0316 8.38389 86.1351 8.28605C86.2385 8.1882 86.3671 8.14068 86.5153 8.14068H88.3016C88.4666 8.14068 88.6008 8.1938 88.7042 8.29723C88.8076 8.40067 88.8607 8.52926 88.8607 8.67743V9.57201C89.6491 8.47056 90.8344 7.91983 92.4111 7.91983C93.9151 7.91983 95.0697 8.39228 95.872 9.33718C96.6772 10.2821 97.1077 11.5513 97.1664 13.1447C97.1804 13.3237 97.1888 13.592 97.1888 13.9499C97.1888 14.3077 97.1804 14.5761 97.1664 14.755C97.1217 16.3177 96.6939 17.5813 95.8832 18.5402C95.0725 19.5019 93.9151 19.9799 92.4111 19.9799C90.9239 19.9799 89.7833 19.4516 88.9949 18.3948V23.4632C88.9949 23.6281 88.9474 23.7567 88.8496 23.8546C88.7517 23.9524 88.6231 23.9999 88.4582 23.9999H86.5153C86.3671 23.9971 86.2385 23.9468 86.1351 23.8518Z"
          fill="#0E0E0E"
        />
        <path
          d="M107.502 14.618C107.516 14.4698 107.524 14.2462 107.524 13.947C107.524 13.6507 107.516 13.4243 107.502 13.2761C107.398 11.3108 106.548 10.3296 104.955 10.3296C104.105 10.3296 103.473 10.6063 103.057 11.1571C102.64 11.7078 102.416 12.3787 102.388 13.1671C102.375 13.346 102.366 13.6284 102.366 14.0141C102.366 14.4027 102.375 14.6935 102.388 14.8836C102.419 15.63 102.648 16.2618 103.079 16.7818C103.51 17.3017 104.136 17.5617 104.955 17.5617C106.548 17.5645 107.398 16.5805 107.502 14.618ZM99.5286 23.8517C99.4224 23.7539 99.3721 23.6253 99.3721 23.4604V8.6774C99.3721 8.51246 99.4252 8.38386 99.5286 8.28602C99.6321 8.18817 99.7579 8.14065 99.9088 8.14065H101.695C101.86 8.14065 101.994 8.19377 102.098 8.2972C102.201 8.40064 102.254 8.52923 102.254 8.6774V9.57198C103.043 8.47053 104.228 7.9198 105.805 7.9198C107.309 7.9198 108.463 8.39225 109.266 9.33715C110.071 10.2821 110.501 11.5512 110.56 13.1447C110.574 13.3236 110.582 13.592 110.582 13.9498C110.582 14.3077 110.574 14.576 110.56 14.755C110.515 16.3177 110.087 17.5813 109.277 18.5402C108.466 19.5018 107.309 19.9799 105.805 19.9799C104.315 19.9799 103.177 19.4515 102.388 18.3948V23.4632C102.388 23.6281 102.341 23.7567 102.243 23.8545C102.145 23.9524 102.017 23.9999 101.852 23.9999H99.9088C99.7607 23.9971 99.6321 23.9468 99.5286 23.8517Z"
          fill="#0E0E0E"
        />
        <path
          d="M113.127 19.5969C113.024 19.4935 112.971 19.3649 112.971 19.2167V4.43372C112.971 4.26878 113.024 4.14019 113.127 4.04234C113.231 3.9445 113.359 3.89697 113.507 3.89697H115.246C115.411 3.89697 115.54 3.9445 115.638 4.04234C115.736 4.14019 115.783 4.26878 115.783 4.43372V19.2167C115.783 19.3649 115.736 19.4935 115.638 19.5969C115.54 19.7003 115.411 19.7534 115.246 19.7534H113.507C113.359 19.7534 113.233 19.7003 113.127 19.5969Z"
          fill="#0E0E0E"
        />
        <path
          d="M6.52965 18.16L8.55923 13.4243C7.23972 12.2278 6.47653 10.5365 6.47653 8.72218C6.47653 5.82317 8.43063 3.37705 11.0892 2.61945C10.0157 1.03995 8.20419 0 6.14945 0C2.85906 0 0.189295 2.66977 0.189295 5.96015C0.189295 8.09038 1.31032 9.95502 2.98766 11.0145C3.16937 11.1292 3.13023 11.459 3.0855 11.5597L0.0998367 18.5346C-0.190902 19.2112 0.175317 19.7675 0.913347 19.7675H6.14945H6.41223C6.26407 19.2587 6.29761 18.6996 6.52965 18.16Z"
          fill="#0E0E0E"
        />
        <path
          d="M18.3096 18.7835L15.9194 13.2036C15.8831 13.1225 15.8523 12.8597 15.9977 12.7674C17.3396 11.9204 18.2369 10.4276 18.2369 8.72505C18.2369 6.09162 16.1011 3.95581 13.4677 3.95581C10.8343 3.95581 8.69846 6.08883 8.69846 8.72226C8.69846 10.4276 9.59584 11.9176 10.9377 12.7646C11.0859 12.8569 11.0523 13.1197 11.016 13.2008L8.62578 18.7807C8.39374 19.3231 8.68448 19.7675 9.27435 19.7675H13.4649H17.6555C18.2481 19.7675 18.5417 19.3231 18.3096 18.7835Z"
          fill="#0E0E0E"
        />
      </svg>
    </div>
  );
};

export default Logo;
