import React, { useState } from 'react';
import clsx from 'clsx';
import { QuestionMarkCircle } from 'src/icons';

export type Props = {
  className?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  label?: React.ReactNode;
  error?: React.ReactNode;
  description?: React.ReactNode;
  'data-testid': string;
  tooltipDescription?: string;
  dense?: boolean;
};

const FormField = ({
  className,
  labelClassName,
  children,
  label,
  error = '',
  description,
  tooltipDescription = '',
  dense,
  ...props
}: Props) => {
  const [tooltipDisplayed, setTooltipDisplayed] = useState(false);

  return (
    <>
      <div
        data-testid="form-field"
        className={clsx(
          'relative',
          error && dense && 'mb-2 pb-0.5',
          !error && dense && 'mb-5',
          error && !dense && 'mb-5 pb-0.5',
          !error && !dense && 'mb-8',
          className,
        )}
      >
        {tooltipDescription !== '' && (
          <div className="absolute right-0 -top-0.5">
            <button
              className="relative group"
              onClick={() => setTooltipDisplayed(!tooltipDisplayed)}
              aria-describedby={`tooltip-${label}`}
              type="button"
            >
              <QuestionMarkCircle
                strokeClasses={clsx(
                  'group-hover:stroke-blue-600',
                  tooltipDisplayed ? 'stroke-blue-600' : 'stroke-grey-900',
                )}
              />
              {tooltipDisplayed && (
                <>
                  <span
                    role="tooltip"
                    id={`tooltip-${label}`}
                    className={clsx(
                      'absolute p-3 text-sm  bg-blue rounded-sm z-20  min-w-tooltip lg:min-w-tooltipLg -bottom-[0] mb-[33px] right-0 lg:-right-48 text-left leading-6 text-grey-900',
                      tooltipDisplayed,
                    )}
                  >
                    {tooltipDescription}
                  </span>
                  <span
                    className={clsx(
                      'absolute w-[5px] h-[5px] rotate-45 bg-blue right-2 z-20 -top-3.5',
                      tooltipDisplayed,
                    )}
                  />
                </>
              )}
            </button>
          </div>
        )}
        <label {...props}>
          <span
            className={clsx(
              `block mb-1 text-sm text-grey-900 min-h-[20px]`,
              tooltipDescription !== '' && 'mr-6',
              labelClassName,
            )}
          >
            {label}
          </span>
          {children}
        </label>
        <div className={clsx('flex flex-col', description && !error && '')}>
          <span
            data-testid={`validation-error-for-${props['data-testid']}`}
            aria-atomic="true"
            aria-live="assertive"
            className={clsx(
              'block text-red-700 text-sm leading-4 min-h-[14px] whitespace-normal',
              error && 'mt-1 mb-1',
            )}
          >
            {error}
          </span>
          {description && (
            <p id="description" className={clsx('text-sm text-grey-700')}>
              {description}
            </p>
          )}
        </div>
      </div>
      {tooltipDisplayed && (
        <>
          <div
            className="absolute w-full h-full left-0 top-0 cursor-default"
            onClick={() => setTooltipDisplayed(!tooltipDisplayed)}
          />
        </>
      )}
    </>
  );
};

export default FormField;
