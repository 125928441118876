import React from 'react';
import clsx from 'clsx';
import { FormTick } from '../../../icons';

export type Props = {
  onChange: (value: string) => void;
  label: string;
  value: string;
  active: boolean;
  disabled?: boolean;
  className?: string;
};

const RadioButton = ({ className, label, value, active, disabled, onChange, ...props }: Props) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value);
  };

  return (
    <label
      htmlFor={value}
      className={clsx(
        'flex flex-row border items-center rounded p-3 relative focus-within:border-blue-700 hover:border-blue-700 transition duration-100 ease-in-out mb-4',
        active ? 'border-blue-600 bg-blue-100' : 'border-grey-500',
        disabled ? 'border-grey-500 hover:border-grey-500 bg-grey-100' : 'cursor-pointer',
      )}
    >
      <input
        id={value}
        type="radio"
        role="radio"
        value={value}
        checked={active}
        aria-checked={active}
        {...props}
        data-testid={`radiobutton-${value}`}
        className={clsx('block h-5 w-5 focus:outline-none peer', className)}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      <span
        className={clsx(
          'absolute left-3 top-[13pxpx] h-5 w-5 rounded-full border  block  transition duration-100 ease-in-out',
          active
            ? disabled
              ? 'bg-grey-500 border-grey-300 peer-focus:border-grey-300 peer-hover:border-grey-300'
              : 'bg-blue-600 border-blue-600'
            : disabled
            ? 'border-grey-500'
            : 'peer-focus:border-blue-700 peer-hover:border-blue-700 bg-grey-100',
        )}
      >
        {active && <FormTick className="absolute top-[0.3rem] left-1" />}
      </span>

      <span className={clsx('block ml-3', active && 'text-blue-600', disabled && 'text-grey-600')}>
        {label}
      </span>
    </label>
  );
};

export default RadioButton;
