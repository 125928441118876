import React from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/icons/types';

type listItem = {
  icon: React.ComponentType<IconProps>;
  description: string | React.ReactNode;
};

export type Props = {
  className?: string;
  children?: React.ReactNode;
  listItems: listItem[];
  itemClassName?: string;
  iconClassName?: string;
  smallGaps?: boolean;
};

const IconList = ({
  className,
  listItems,
  itemClassName,
  iconClassName,
  smallGaps,
  ...props
}: Props) => {
  return (
    <ul {...props} data-testid="iconlist" className={clsx('', className)}>
      {listItems.map((listItem, ix) => (
        <li
          key={ix}
          className={clsx(
            smallGaps ? 'mb-1.5' : 'mb-7',
            'flex flex-row items-center last:mb-0 text-grey-900',
            itemClassName,
          )}
          data-testid="iconlistItem"
        >
          <span className={clsx(smallGaps ? 'mr-2' : 'mr-4', 'self-center')}>
            <listItem.icon
              className={clsx(smallGaps ? 'w-6' : 'w-8', 'h-8 text-blue-500', iconClassName)}
            />
          </span>
          {listItem.description}
        </li>
      ))}
    </ul>
  );
};

export default IconList;
