import clsx from 'clsx';
import { Dispatch, SVGProps } from 'react';

type TabProps = {
  name: string;
  href: string;
  component: () => JSX.Element;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

type SideTabsProps = {
  activeTabIndex: { parent: number; children: number | undefined };
  setActiveTabIndex: Dispatch<{ parent: number; children: number | undefined }>;
  subNavigation: (TabProps & { children?: TabProps[] })[];
};

const SideTabs = ({ subNavigation, activeTabIndex, setActiveTabIndex }: SideTabsProps) => {
  const ActiveTab =
    activeTabIndex.children != undefined
      ? subNavigation[activeTabIndex.parent].children[activeTabIndex.children]
      : subNavigation[activeTabIndex.parent];

  return (
    <>
      <div
        data-testid="side-tabs"
        className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
      >
        <aside className="py-6 lg:col-span-3">
          <nav className="space-y-1">
            {subNavigation.map((item, ix) => {
              const current = ix === activeTabIndex.parent;
              return (
                <div
                  key={`${item.name}-${ix}`}
                  className={clsx(
                    current
                      ? 'border-blue-500 text-blue-500 group border-l-4 transition-all duration-200 ease-in-out'
                      : null,
                  )}
                >
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => setActiveTabIndex({ parent: ix, children: undefined })}
                    className={clsx(
                      current && activeTabIndex.children == undefined
                        ? 'bg-indigo-50 border-blue-500 text-blue-500'
                        : 'border-transparent text-gray-900 hover:bg-indigo-50 hover:bg-opacity-50 hover:text-gray-900',
                      'group px-3 py-3 flex items-center',
                    )}
                    aria-current={current ? 'page' : undefined}
                  >
                    <item.icon
                      className={clsx(
                        current && activeTabIndex.children == undefined
                          ? 'text-blue-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6 transition-all duration-200 ease-in-out',
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                  <div className="">
                    {current
                      ? item.children?.map((child, iy) => {
                          const current_child = iy === activeTabIndex.children;
                          return (
                            <a
                              key={child.name}
                              href={child.href}
                              onClick={() => setActiveTabIndex({ parent: ix, children: iy })}
                              className={clsx(
                                current_child
                                  ? 'bg-indigo-50 border-blue-500 text-blue-500'
                                  : 'border-transparent text-gray-900 hover:bg-indigo-100 hover:bg-opacity-50 hover:text-gray-900',
                                'group px-3 py-3 flex items-center',
                              )}
                              aria-current={current_child ? 'page' : undefined}
                            >
                              <div className="pl-4 flex flex-row">
                                <child.icon
                                  className={clsx(
                                    current_child
                                      ? 'text-blue-500'
                                      : 'text-gray-400 group-hover:text-gray-500',
                                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6 transition-all duration-200 ease-in-out',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="truncate">{child.name}</span>
                              </div>
                            </a>
                          );
                        })
                      : null}
                  </div>
                </div>
              );
            })}
          </nav>
        </aside>

        <div
          data-testid="side-tabs-body"
          className="divide-y divide-gray-200 lg:col-span-9 py-6 px-4"
        >
          <ActiveTab.component />
        </div>
      </div>
    </>
  );
};

export default SideTabs;
