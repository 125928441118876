import React from 'react';
import clsx from 'clsx';
import Pill, { PillTypes } from 'src/components/atoms/Pill/Pill';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { IndicatorPillTypes } from 'src/screens/types';

export type RiskIndicatorType = {
  type: IndicatorPillTypes;
  name: string;
};

type Props = RiskIndicatorType & {
  className?: string;
  children?: React.ReactNode;
};

const Icons: Record<IndicatorPillTypes, React.ReactNode> = {
  [PillTypes.SUCCESS]: <CheckCircleIcon className="text-green-400 h-6 w-6 flex-none" />,
  [PillTypes.WARNING]: <ExclamationCircleIcon className="text-amber-400 h-6 w-6 flex-none" />,
  [PillTypes.INCONCLUSIVE]: <ExclamationCircleIcon className="text-grey-400 h-6 w-6 flex-none" />,
};

const PillText: Record<IndicatorPillTypes, string> = {
  [PillTypes.SUCCESS]: 'Clear',
  [PillTypes.WARNING]: 'Consider',
  [PillTypes.INCONCLUSIVE]: 'N/A',
};

const RiskIndicator = ({ className, type, name, ...props }: Props) => {
  return (
    <div
      {...props}
      data-testid="risk-indicator"
      className={clsx('flex flex-row items-center w-full px-4 space-x-3 mb-5', className)}
    >
      {Icons[type]}
      <p className="text-sm text-left text-grey-700 w-full">{name}</p>
      <Pill type={type}>{PillText[type]}</Pill>
    </div>
  );
};

export default RiskIndicator;
