import React from 'react';
import clsx from 'clsx';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export type Props = PhoneInputProps & {
  className?: string;
  onChange: (newValue: string) => void;
  value: string;
  placeholder: string;
  error?: boolean;
};

const variantClasses = (error) =>
  clsx(
    'h-12 box-border rounded',
    error
      ? 'border-red-400 text-red-800'
      : 'text-grey-900 border-grey-500 group-hover:border-blue-700 group-focus-within:border-blue-300',
    'transition duration-100 ease-in-out',
  );

const PhoneNumberInput = ({ className, error, onChange, ...props }: Props) => {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <ReactPhoneInput
      containerClass={clsx('group', className)}
      inputClass={clsx(variantClasses(error), 'pl-16 pr-4 text-base w-full')}
      inputProps={{
        'aria-invalid': error,
        'data-testid': 'phone-number-input',
        autoComplete: 'tel',
      }}
      buttonClass={clsx(variantClasses(error), 'rounded-r-none px-2 bg-grey-100 hover:bg-blue-100')}
      country={'gb'}
      searchClass="box-border border-b border-grey-500"
      enableSearch
      disableSearchIcon
      dropdownClass="text-base rounded overscroll-none"
      onChange={handleChange}
      {...props}
    />
  );
};

export default PhoneNumberInput;
