import React, { ButtonHTMLAttributes, PropsWithChildren, useState } from 'react';
import type { FunctionComponent } from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid';
import cn from 'classnames';

type TableProps = {
  data: Array<{ [key: string]: string | number | Date }>;
  columns: Array<{ name: string }>;
  onRowClick?: () => void;
};

const Table: FunctionComponent<TableProps> = (props) => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [data, setData] = useState(props.data);

  return (
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="overflow-hidden border border-gray-200 rounded-lg overflow-y-auto h-96">
          <table className="min-w-full divide-y divide-gray-200 table-fixed">
            <thead className="bg-tertiary text-white">
              <tr className="sticky top-0 bg-gray-100">
                {props.columns.map((column, ix) => {
                  return <TableColumn {...column} key={`column-${ix}`} />;
                })}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 h-4">
              {data.slice(page * pageSize, page * pageSize + pageSize).map((d, ix) => (
                <TableRow data={d} key={`column-${ix}`} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between pt-2">
          <p className="text-sm">
            Showing {Math.min(data.length, (page + 1) * pageSize)} of {data.length} transactions
          </p>
          <TablePagination
            nextPage={() => setPage(Math.min(Math.floor(data.length / pageSize), page + 1))}
            prevPage={() => setPage(Math.max(0, page - 1))}
            lastPage={() => setPage(Math.floor(data.length / pageSize) - 1)}
            firstPage={() => setPage(0)}
            isLastPage={page === Math.floor(data.length / pageSize) - 1}
            isFirstPage={page === 0}
          />
        </div>
      </div>
    </div>
  );
};

type TableColumnProps = {
  [key: string]: string;
};

const TableColumn: FunctionComponent<TableColumnProps> = ({ name }) => {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-left text-small font-semibold text-black tracking-wider w-24"
    >
      {name}
    </th>
  );
};

type TableRowProps = {
  data: { [key: string]: string | number | Date };
};

const TableRow: FunctionComponent<TableRowProps> = ({ data }) => {
  return (
    <tr className="hover:bg-gray-200 h-4">
      {Object.entries(data).map(([key, value], index) => (
        <td className="px-6 py-4 whitespace-nowrap text-sm" key={`row-cell-${index}`}>
          {value instanceof Date
            ? value.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
            : value}
        </td>
      ))}
    </tr>
  );
};

type PageButtonProps = {
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PageButton: FunctionComponent<PropsWithChildren<PageButtonProps>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={cn(
        'relative inline-flex items-center px-2 py-2 border border-gray-300',
        'bg-white text-sm font-medium text-gray-200 hover:bg-gray-50 disabled:bg-gray-100',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

type PaginationProps = {
  nextPage: () => void;
  prevPage: () => void;
  lastPage: () => void;
  firstPage: () => void;
  isLastPage: boolean;
  isFirstPage: boolean;
};

const TablePagination: FunctionComponent<PaginationProps> = ({
  isLastPage,
  isFirstPage,
  nextPage,
  prevPage,
  lastPage,
  firstPage,
}) => {
  return (
    <nav className="relative z-0 inline-flex rounded-lg shadow-sm -space-x-px">
      <PageButton className="rounded-l-md" disabled={isFirstPage} onClick={firstPage}>
        <span className="sr-only">First</span>
        <ChevronDoubleLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </PageButton>
      <PageButton disabled={isFirstPage} onClick={prevPage}>
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </PageButton>
      <PageButton disabled={isLastPage} onClick={nextPage}>
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </PageButton>
      <PageButton className="rounded-r-md" disabled={isLastPage} onClick={lastPage}>
        <span className="sr-only">Last</span>
        <ChevronDoubleRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </PageButton>
    </nav>
  );
};

export default Table;
