import React from 'react';
import clsx from 'clsx';

export type Props = {
  className?: string;
  file?: string;
};

const PdfViewer = ({ className, file, ...props }: Props) => {
  return (
    <object
      {...props}
      data-testid="pdf-viewer"
      className={clsx('w-full h-full', className)}
      data={file}
      type="application/pdf"
    >
      <div className="p-2 border">
        <p>Could not display file.</p>
      </div>
    </object>
  );
};

export default PdfViewer;
