import React from 'react';
import clsx from 'clsx';

export type Props = {
  className?: string;
  items: { key: string; value: string }[];
  title?: string;
};

const ShadedGrid = ({ className, items, title, ...props }: Props) => {
  return (
    <div {...props} data-testid="shaded-grid" className={clsx('-mx-4 mt-6', className)}>
      <h3 className="text-xl font-medium mb-5 ml-4 lg:ml-8">{title}</h3>
      <div className="grid lg:grid-cols-2">
        {items.map((item, ix) => (
          <div
            key={`${item.key}${ix}`}
            className={clsx(
              'py-6 lg:py-3 px-4',
              Math.floor(ix / 2) % 2 === 0 ? 'lg:bg-grey-200' : 'lg:bg-white',
              ix % 2 === 0 ? 'bg-grey-200' : 'bg-white',
            )}
          >
            <dt className="font-medium text-gray-500 mb-1">{item.key}</dt>
            <dd className="text-gray-900">{item.value}</dd>
          </div>
        ))}
        <div
          className={clsx(
            'hidden lg:block',
            Math.floor(items.length / 2) % 2 === 0 && 'bg-grey-200',
          )}
        />
      </div>
    </div>
  );
};

export default ShadedGrid;
