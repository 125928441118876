import clsx from 'clsx';
import { OptionComponentProps, Options } from './types';
import TextOption from './TextOption';
import { useRef, forwardRef, useEffect } from 'react';

export type OptionsListProps = {
  className?: string;
  onClose: () => void;
  options: Options;
  OptionComponent?: React.ComponentType<OptionComponentProps>;
  onSelectValue: (newValue: string) => void;
  value: string;
  onKeyboardNext: () => void;
  onKeyboardPrev: () => void;
};

const OptionsList = forwardRef<HTMLUListElement, OptionsListProps>(
  (
    {
      className,
      onClose,
      options,
      OptionComponent = TextOption,
      onSelectValue,
      value,
      onKeyboardNext,
      onKeyboardPrev,
      ...props
    },
    ref,
  ) => {
    const selectedRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
      if (ref && typeof ref !== 'function' && ref.current) {
        ref.current.focus();
      }

      const target = selectedRef?.current;
      if (target && target.parentNode) {
        const parentNode = target.parentNode as HTMLElement;
        parentNode.scrollTop = target.offsetTop - target.offsetHeight;
      }
    }, [ref]);

    const handleKeyBoardInput = (e: React.KeyboardEvent) => {
      if (e.key !== 'Tab') {
        e.preventDefault();
      }
      const target = selectedRef.current;
      switch (e.key) {
        case 'ArrowDown': {
          if (target && target.parentNode) {
            (target.parentNode as HTMLElement).scrollTop = target.offsetTop;
          }

          onKeyboardNext();
          break;
        }
        case 'ArrowUp': {
          if (target && target.parentNode) {
            (target.parentNode as HTMLElement).scrollTop =
              target.offsetTop - target.offsetHeight * 2;
          }

          onKeyboardPrev();
          break;
        }
        case 'Enter':
        case 'Escape':
        case ' ': {
          onClose();
          break;
        }
      }
    };

    return (
      <>
        <ul
          ref={ref}
          tabIndex={-1}
          role="listbox"
          onBlur={onClose}
          onKeyDown={handleKeyBoardInput}
          aria-activedescendant={value}
          className={clsx(
            className,
            'max-h-60 overflow-y-scroll',
            'box-border rounded bg-white border-black border py-1 select-none focus:outline-none',
          )}
          {...props}
        >
          {options.map((option) => (
            <OptionComponent
              key={option.value}
              onClick={() => onSelectValue(option.value)}
              selected={option.value === value}
              ref={option.value === value ? selectedRef : undefined}
              Icon={option.icon}
              {...option}
            />
          ))}
        </ul>
      </>
    );
  },
);

OptionsList.displayName = 'OptionsList';

export default OptionsList;
