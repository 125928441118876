import React from 'react';
import clsx from 'clsx';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  data: { header: string; value: string }[];
};

const ShadedVerticalTable = ({ className, data, ...props }: Props) => {
  return (
    <table>
      <tbody
        {...props}
        data-testid="shaded-vertical-table"
        className={clsx('flex flex-col w-full mb-6 lg:mb-0', className)}
      >
        {data.map((row, index) => (
          <tr
            key={row.header}
            className={clsx(
              'w-full grid lg:grid-cols-7 gap-x-4 py-4 lg:py-6 px-2 lg:px-8',
              index % 2 === 0 ? 'bg-grey-100' : 'bg-white',
            )}
          >
            <th className="text-grey-600 font-normal text-left lg:col-span-3 truncate self-center">
              {row.header}
            </th>
            <td title={row.value} className="lg:col-span-4 line-clamp-3 text-sm break-all">
              {row.value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ShadedVerticalTable;
