import React from 'react';
import clsx from 'clsx';
import { ChevronRight, FormTick } from 'src/icons';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  crumbs: { title: string; completed: boolean; active: boolean }[];
};

const Breadcrumb = ({ className, crumbs, ...props }: Props) => {
  return (
    <div
      {...props}
      data-testid="breadcrumb"
      className={clsx(
        'flex flex-row items-center space-x-4 w-full md:w-auto md:justify-start',
        className,
      )}
    >
      {crumbs.map(({ title, completed, active }, ix) => (
        <React.Fragment key={title}>
          <div
            className={clsx(
              'text-xxs font-medium flex flex-row items-center',
              active
                ? 'font-semibold text-blue-500'
                : completed
                ? 'text-blue-700'
                : 'text-blue-400',
            )}
          >
            {completed && !active && (
              <span className="relative h-4 w-4 border border-blue-700 rounded-full mr-2 flex-none">
                <FormTick className="absolute top-[3px] left-0.5 text-blue-700" strokeClasses="" />
              </span>
            )}
            {title}
          </div>
          {ix !== crumbs.length - 1 && (
            <ChevronRight
              aria-label="breadcrumb icon"
              className={clsx(
                'md:mx-8 ',
                active ? 'text-blue-500' : completed ? 'text-blue-700' : 'text-blue-400',
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
