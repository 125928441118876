import React from 'react';
import { TopRightLine, TopRightLine2 } from './TopRightLines';
import { BottomLeftLine, BottomLeftLine2 } from './BottomLeftLines';
export type Props = {
  className?: string;
  name: 'topRight' | 'topRight2' | 'bottomLeft' | 'bottomLeft2';
};

const LineArt = ({ name }: Props) => {
  return (
    <>
      {name === 'topRight' && <TopRightLine />}
      {name === 'topRight2' && <TopRightLine2 />}
      {name === 'bottomLeft' && <BottomLeftLine />}
      {name === 'bottomLeft2' && <BottomLeftLine2 />}
    </>
  );
};

export default LineArt;
