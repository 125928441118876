import React from 'react';
import clsx from 'clsx';

export type toggleOption = {
  label: React.ReactNode;
  value: boolean;
};
export type Props = {
  className?: string;
  children?: React.ReactNode;
  options: toggleOption[];
  value: boolean;
  onChange: (value: boolean) => void;
};

const ToggleButton = ({
  pressed,
  onClick,
  value,
  label,
  ix,
}: {
  pressed: boolean;
  onClick: (value: boolean) => void;
  value: boolean;
  label: React.ReactNode;
  ix: number;
}) => (
  <button
    className={clsx(
      'w-full py-3.5 px-7 border box-border',
      ix === 0 ? 'rounded-l' : 'rounded-r',
      pressed
        ? 'bg-blue-100 border-blue-600 text-blue-600'
        : 'bg-white text-grey-600 border-grey-600',
      !pressed && ix === 0 && 'border-r-0',
      !pressed && ix === 1 && 'border-l-0',
      'transition duration-100 ease-in-out',
    )}
    aria-pressed={pressed}
    onClick={() => onClick(value)}
    type="button"
    data-testid={`toggle-${value}`}
  >
    {label}
  </button>
);

const Toggle = ({ className, options, value, onChange, ...props }: Props) => {
  const handleClick = (value: boolean) => {
    onChange(value);
  };

  return (
    <div {...props} data-testid="toggle" className={clsx('flex flex-row', className)}>
      {options.map((option, ix) => (
        <ToggleButton
          key={option.value.toString()}
          ix={ix}
          label={option.label}
          value={option.value}
          pressed={value === option.value}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export default Toggle;
