import { Pill } from 'src/components';
import { PillTypes } from 'src/components/atoms/Pill/Pill';
import { DecisionString } from 'src/screens/types';

type Props = {
  decision?: DecisionString;
  className?: string;
};

const decisionToPillType: Record<DecisionString, PillTypes> = {
  [DecisionString.ACCEPTABLE]: PillTypes.SUCCESS,
  [DecisionString.CONSIDER]: PillTypes.WARNING,
  [DecisionString.INCONCLUSIVE]: PillTypes.INCONCLUSIVE,
  [DecisionString.HIGH_RISK]: PillTypes.ERROR,
};

const DecisionPill = ({ decision, ...props }: Props) =>
  decision ? (
    <Pill {...props} type={decisionToPillType[decision]} dot>
      {decision}
    </Pill>
  ) : null;

export default DecisionPill;
