import React, { useMemo, useState, useRef, useCallback, DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import OptionsList from './OptionsList';
import { Option, Options, OptionComponentProps } from './types';
import { ChevronDown } from 'src/icons';
import { ButtonHTMLAttributes } from 'react';

export type ButtonContentsProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  placeholder?: string;
  option?: Option;
  error?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  IconComponent?: React.ElementType;
};

export type DropdownProps = {
  label: string;
  ButtonContentsComponent?: React.ComponentType<ButtonContentsProps>;
  className?: string;
  disabled?: boolean;
  'data-testid'?: string;
  onSelectValue: (newValue: string) => void;
  options: Options;
  OptionComponent?: React.ComponentType<OptionComponentProps>;
  value: string | undefined;
  selectStyle?: { [key: string]: string };
  error?: boolean;
  placeholder?: string;
  below?: boolean;
  onClose?: (value?: string) => void;
  large?: boolean;
  IconComponent?: React.ElementType;
};

const ButtonContents = ({
  placeholder,
  option,
  error,
  isOpen,
  disabled,
  IconComponent = ChevronDown,
  ...props
}: ButtonContentsProps) => (
  <button
    {...props}
    className={clsx(
      'w-full h-12 bg-white px-4 py-3 text-sm',
      error ? 'border-red-400 text-red-800' : 'border-grey-500',
      'border rounded',
      disabled
        ? 'text-grey-600 bg-grey-300 border-grey-600'
        : 'text-black hover:border-blue-700 focus:border-blue-700',
    )}
    disabled={disabled}
  >
    <div className={clsx('flex flex-no-wrap items-center', !option && 'text-grey-2')}>
      {option?.icon && <option.icon className="mr-3" />}
      <div className={option ? 'text-grey-900' : 'text-gray-400'}>
        {option ? option.label : placeholder}
      </div>
    </div>
    <span className={clsx('absolute right-4 top-3.5', isOpen ? 'rotate-180' : 'rotate-0')}>
      <IconComponent strokeClasses={clsx(disabled && 'stroke-grey-600')} />
    </span>
  </button>
);

const DropDown = ({
  ButtonContentsComponent = ButtonContents,
  className,
  disabled,
  IconComponent = ChevronDown,
  label,
  onSelectValue,
  options,
  OptionComponent,
  placeholder,
  value = '',
  error,
  below,
  onClose,
  large,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const selectedIndex = useMemo(
    () => options.findIndex((option) => option.value === value),
    [value, options],
  );

  const keyboardSelectNext = () => {
    if (selectedIndex < options.length - 1) {
      onSelectValue(options[selectedIndex + 1].value);
    }
  };

  const keyboardSelectPrev = () => {
    if (selectedIndex > 0) {
      onSelectValue(options[selectedIndex - 1].value);
    }
  };

  const toggleModal = (e: React.MouseEvent) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose(value);
    }
  }, [onClose, value]);

  const onSelectAndClose = useCallback(
    (newValue: string) => {
      onSelectValue(newValue);
      setIsOpen(false);
      if (onClose) {
        onClose(newValue);
      }
    },
    [onSelectValue, onClose],
  );

  const handleNativeChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    onSelectValue(target.value);
  };

  return (
    <>
      <div
        className={clsx(
          error ? 'border-red-3 text-red-5' : 'border-grey-500 text-black',
          'md:hidden',
          'border rounded p-1 pr-4 bg-white',
          'h-12',
          large ? 'w-full' : 'w-64',
          'relative',
          className,
          disabled ? 'text-grey-600 bg-grey-300' : 'bg-white text-black',
        )}
        data-testid={`${props['data-testid']}-native`}
      >
        <select
          aria-invalid={error}
          aria-label={label}
          onChange={handleNativeChange}
          value={value || ''}
          disabled={disabled}
          className={clsx(
            'w-full h-full focus:outline-none pl-4',
            'appearance-none relative',
            'bg-no-repeat',
            disabled ? 'bg-grey-300' : 'bg-white',
          )}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        <span className={clsx('absolute right-4 top-3.5')}>
          <IconComponent strokeClasses={clsx(disabled && 'stroke-grey-600')} />
        </span>
      </div>

      <div
        className={clsx(
          'hidden md:block',
          'relative box-border h-12',
          large ? 'w-full' : 'w-64',
          className,
        )}
        data-testid="desktop-dropdown"
        {...props}
      >
        <ButtonContentsComponent
          aria-expanded={isOpen ? true : undefined}
          aria-label={label}
          aria-invalid={error}
          role="listbox"
          disabled={disabled}
          onClick={toggleModal}
          placeholder={placeholder}
          option={options[selectedIndex]}
          error={error}
          isOpen={isOpen}
        />

        {isOpen && (
          <OptionsList
            aria-label={label}
            className={clsx(below ? 'absolute top-14' : 'absolute top-14 left-0', 'z-10 w-full')}
            onClose={closeModal}
            onSelectValue={onSelectAndClose}
            onKeyboardNext={keyboardSelectNext}
            onKeyboardPrev={keyboardSelectPrev}
            OptionComponent={OptionComponent}
            options={options}
            value={value}
            ref={listRef}
          />
        )}
      </div>
    </>
  );
};

export default DropDown;
