import ButtonBase, { ButtonProps } from './ButtonBase';
import clsx from 'clsx';

type Props = ButtonProps & {
  secondary?: boolean;
  outline?: boolean;
  inverse?: boolean;
  text?: boolean;
};

const getClasses = ({ secondary, outline, inverse, text }: Partial<Props>) => {
  const outlineDisabledClasses =
    'disabled:bg-grey-300 disabled:text-grey-600 disabled:border-grey-600';
  const disabledClasses = 'disabled:bg-grey-500 disabled:text-grey-600';

  if (text) {
    return clsx(
      'py-3 h-12',
      'text-blue-500 underline hover:text-blue-400 active:text-blue-600 !px-0 py-0 md:py-0 md:!px-0 text-sm h-auto w-min -mx-3',
    );
  }

  if (outline && inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-none text-white border border-white',
      'hover:bg-opacity-5 hover:bg-black',
      'focus-visible:bg-white active:bg-white focus-visible:bg-opacity-10 active:bg-opacity-10',
      outlineDisabledClasses,
    );
  }

  if (secondary && outline && !inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-blue text-blue-500 border border-blue-500',
      'hover:bg-blue',
      'focus-visible:bg-blue-200 active:bg-blue-200',
      outlineDisabledClasses,
    );
  }

  if (secondary && !outline && inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-white text-blue-500',
      'hover:bg-blue',
      'focus-visible:bg-blue-200 active:bg-blue-200',
      disabledClasses,
    );
  }

  if (secondary && !outline && !inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-blue-500 text-white',
      'hover:bg-blue-500',
      'focus-visible:bg-blue-700 active:bg-blue-700',
      disabledClasses,
    );
  }

  if (!secondary && outline && !inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-blue-100 text-blue-600',
      'border border-blue-600',
      'hover:bg-blue',
      'focus-visible:bg-blue-200 active:bg-blue-200',
      outlineDisabledClasses,
    );
  }

  if (!secondary && !outline && inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-white text-blue-600',
      'hover:bg-blue',
      'focus-visible:bg-blue-200 active:bg-blue-200',
      disabledClasses,
    );
  }

  if (!secondary && !outline && !inverse) {
    return clsx(
      'px-3 md:px-8 py-3 h-12',
      'bg-blue-600 text-white',
      'hover:bg-blue-500',
      'focus-visible:bg-blue-700 active:bg-blue-700',
      disabledClasses,
    );
  }
};

const Button = ({ secondary, outline, inverse, text, className, ...props }: Props) => {
  return (
    <ButtonBase
      className={clsx(getClasses({ secondary, outline, inverse, text }), className)}
      {...props}
    />
  );
};

export default Button;
