import React from 'react';
import clsx from 'clsx';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { Pill } from 'src/components/atoms';
import { PillTypes } from 'src/components/atoms/Pill/Pill';
import { FindingsPillTypes } from 'src/screens/risk-report/types';

export type Props = {
  className?: string;
  title?: string;
  items: { title: string; subtitle: string; type: FindingsPillTypes; pillText: string }[];
};

const iconMap: Record<FindingsPillTypes, JSX.Element> = {
  [PillTypes.SUCCESS]: <CheckCircleIcon className="text-green-400 h-5 w-5" />,
  [PillTypes.ERROR]: <XCircleIcon className="text-red-400 h-5 w-5" />,
};

const FindingsSummary = ({ className, title, items, ...props }: Props) => {
  return (
    <div {...props} data-testid="findings-summary" className={clsx('lg:px-4 mt-6', className)}>
      <h3 className="text-xl font-medium mb-5">{title}</h3>
      {items.map((item) => (
        <div key={item.title} className="border-b py-5">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div className="flex flex-row space-x-4 items-center mb-4 lg:mb-0">
              {iconMap[item.type]}
              <div>
                <dt className="font-medium">{item.title}</dt>
                <dd>{item.subtitle}</dd>
              </div>
            </div>
            <Pill className="ml-8 w-min px-12 lg:px-3" type={item.type}>
              {item.pillText}
            </Pill>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FindingsSummary;
