import { ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useState } from 'react';

type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
};

const SingleAccordian = ({ title, subtitle, children, className }: Props) => {
  const [open, setOpen] = useState(true);

  return (
    <div
      data-testid="single-accordian"
      className={clsx(!open && 'border-b lg:border-none', className)}
    >
      <button
        onClick={() => setOpen(!open)}
        className="py-4 flex flex-row justify-between w-full text-left items-center bg-white relative z-10 group space-x-4"
      >
        <div className="absolute group-hover:bg-blue group-hover:bg-opacity-50 transition-all duration-300 rounded-md h-full w-[calc(100%+32px)] -left-4 z-0" />
        <div className="w-full z-10">
          <h4 className="text-xl font-medium">{title}</h4>
          <p className="text-grey-800">{subtitle}</p>
        </div>
        <div className="">
          <ChevronDownIcon
            className={clsx(
              'text-blue-700 h-8 w-8 transition-all duration-300',
              open ? 'rotate-180' : 'rotate-0',
            )}
          />
        </div>
      </button>

      <div
        className={clsx(
          'grid w-full lg:grid-cols-2 transition-all duration-300 ease-linear',
          open
            ? 'h-auto translate-y-0 opacity-100 py-6 border-b-4 border-grey-300'
            : 'h-0 max-h-0 -translate-y-[10%] opacity-0',
        )}
      >
        {open && children}
      </div>
    </div>
  );
};

export default SingleAccordian;
