import React from 'react';
import clsx from 'clsx';
import 'flatpickr/dist/themes/light.css';
import { Input } from 'src/components';
import { Calendar } from 'src/icons';

import Flatpickr from 'react-flatpickr';
import { format, formatISO } from 'date-fns';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  error?: React.ReactNode;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  maxDate?: Date;
  minDate?: Date;
};

const DatePicker = ({
  className,
  disabled,
  error,
  value,
  onChange,
  minDate,
  maxDate = new Date(),
  onBlur,
  ...props
}: Props) => {
  const onChangeHandler = (selectedDates) => {
    onChange(formatISO(selectedDates[0], { representation: 'date' }));
  };

  return (
    <div data-testid="datepicker" className={clsx('w-full', className)} {...props}>
      <Flatpickr
        onChange={onChangeHandler}
        defaultValue={value ? format(new Date(value), 'dd/MM/yyyy') : ''}
        options={{ minDate, maxDate, dateFormat: 'd/m/Y', disableMobile: true }}
        onClose={onBlur}
        render={({ defaultValue, value, ...props }, ref) => {
          return (
            <Input
              value={value || defaultValue}
              inputRef={ref}
              iconRight={Calendar}
              disabled={disabled}
              error={error}
              readOnly
              {...props}
            />
          );
        }}
      />
    </div>
  );
};

export default DatePicker;
