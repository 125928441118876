/* eslint-disable @next/next/no-img-element */
import React from 'react';
import clsx from 'clsx';
import PdfViewer from '../PdfViewer';
import Button from '../Button';
import { DocumentDuplicateIcon } from '@heroicons/react/outline';

export type Props = {
  className?: string;
  documents: {
    path: string;
    pdf?: boolean;
    name?: string;
  }[];
  activeIndex: number;
  setActiveIndex: (number) => void;
};

const MultiPDFViewer = ({ className, documents, activeIndex, setActiveIndex, ...props }: Props) => {
  const isPdf = documents[activeIndex].pdf || true;
  const path = documents[activeIndex].path;
  return (
    <div
      {...props}
      data-testid="multi-image-viewer"
      className={clsx('h-full lg:w-full flex flex-col justify-between', className)}
    >
      <div className={clsx('w-full', isPdf ? 'min-h-[600px]' : 'min-h-[400px]')}>
        {isPdf ? (
          <PdfViewer file={path} />
        ) : (
          <img alt="" src={path} className="w-96 max-h-96 object-contain" />
        )}
      </div>

      <div
        className={clsx(
          `flex flex-row space-x-2 mt-4 w-full`,
          isPdf ? 'overflow-x-scroll' : 'overflow-x-auto',
        )}
      >
        {documents.map((d, ix) => (
          <div
            key={ix}
            className={clsx(
              'flex flex-col p-2 rounded relative items-center box-border',
              ix === activeIndex && 'border-2 border-blue-500',
            )}
          >
            <div className="w-20 h-20 flex-none">
              {d.pdf !== false ? (
                <PdfViewer file={d.path} />
              ) : (
                <img alt="" src={d.path} className="w-20 max-h-20 object-contain" />
              )}
            </div>
            <div>{d.name || `V${ix + 1}&V${ix + 2} comparison`}</div>
            <button
              className="h-full w-full bg-transparent top-0 left-0 absolute"
              onClick={() => setActiveIndex(ix)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiPDFViewer;
