import { PostcoderAddress } from './postcoderApi';
import UKAddressAutoComplete from './UKAddressAutoComplete';
import InternationalAddressAutoComplete from './InternationalAddressAutoComplete';

type Props = {
  countryCode: string;
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  className?: string;
};

const AddressAutoComplete = ({ countryCode, ...props }: Props) =>
  countryCode && countryCode !== 'GB' ? (
    <InternationalAddressAutoComplete {...props} countryCode={countryCode} />
  ) : (
    <UKAddressAutoComplete {...props} />
  );

export default AddressAutoComplete;
