import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { MultiImageViewer, ShadedVerticalTable, DocumentResultsInfo } from 'src/components';
import { DecisionString, DocResult } from 'src/screens/types';

const BarcodeCheckResult = ({
  document,
  className,
}: {
  document: DocResult;
  className?: string;
}) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const images = useMemo(() => document.barcode_results?.map((r) => r.extracted_image), [document]);

  const tableData = [
    {
      header: 'Data',
      value: document.barcode_results[activeImageIndex].decoded_data,
    },
    {
      header: 'Created',
      value: document.barcode_results[activeImageIndex].created_at,
    },
    {
      header: 'Type',
      value: document.barcode_results[activeImageIndex].code_type,
    },
    {
      header: 'Quality',
      value: document.barcode_results[activeImageIndex].quality,
    },
  ];

  return (
    <div
      data-testid="barcode-check-result"
      className={clsx('col-span-full grid lg:grid-cols-4', className)}
    >
      <div className="w-full min-h-[100px] flex items-center justify-center">
        <MultiImageViewer
          images={images}
          activeImageIndex={activeImageIndex}
          setActiveImageIndex={setActiveImageIndex}
        />
      </div>
      {document.decision_results?.qr_analysis === DecisionString.ACCEPTABLE ? (
        <DocumentResultsInfo
          className="lg:col-span-3 pr-0"
          decision={document.decision_results?.qr_analysis}
          title="Barcode Decision"
          bullets={[`${document.barcode_results.length} Barcode(s) detected`]}
        >
          <ShadedVerticalTable data={tableData} />
        </DocumentResultsInfo>
      ) : (
        <div className="lg:col-span-3 pr-0 lg:w-full lg:ml-8">
          <ShadedVerticalTable data={tableData} />
        </div>
      )}
    </div>
  );
};

export default BarcodeCheckResult;
