import React from 'react';
import clsx from 'clsx';
import { RiskIndicator, DecisionPill } from 'src/components';
import { DecisionString } from 'src/screens/types';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  title: string;
  bullets: string[];
  riskIndicators?: React.ComponentProps<typeof RiskIndicator>[];
  decision?: DecisionString;
};

const DocumentResultsInfo = ({
  title,
  decision,
  bullets,
  riskIndicators,
  children,
  className,
  ...props
}: Props) => {
  return (
    <div {...props} data-testid="document-results-info" className={clsx('lg:px-16', className)}>
      <div className="flex flex-row w-full justify-between items-center mb-3">
        <p className="font-medium">{title}</p>
        {decision && <DecisionPill decision={decision} />}
      </div>
      <ul className="list-disc ml-4">
        {bullets.map((b) => (
          <li key={b} className="text-sm text-grey-700">
            {b}
          </li>
        ))}
      </ul>
      <div className="my-6 border-b w-full" />
      {riskIndicators && (
        <>
          <div className="text-grey-800 font-medium mb-6">Risk Indicators</div>
          {riskIndicators.map((ri) => (
            <RiskIndicator key={ri.name} {...ri} />
          ))}
        </>
      )}
      {children}
    </div>
  );
};

export default DocumentResultsInfo;
