import { Header } from 'src/components';

const Body = ({ children }) => (
  <main data-testid="overlap-page-template" className="relative -mt-32">
    <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
      <div className="bg-white rounded-lg shadow overflow-hidden">{children}</div>
    </div>
  </main>
);

const OverlapPageTemplate = ({ children }) => {
  return <div>{children}</div>;
};

OverlapPageTemplate.Header = Header;
OverlapPageTemplate.Body = Body;

export default OverlapPageTemplate;
