import React, { ElementType } from 'react';
import clsx from 'clsx';
import { IconProps } from 'src/icons/types';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: ElementType;
  href?: string;
  target?: string;
  className?: string;
  iconLeft?: React.ComponentType<IconProps>;
  iconRight?: React.ComponentType<IconProps>;
  children?: React.ReactNode;
  outline?: boolean;
};

const ButtonBase = ({
  as: Component = 'button',
  className,
  children,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...props
}: ButtonProps) => {
  return (
    <Component
      {...props}
      data-testid="button"
      className={clsx(
        'flex flex-row items-center justify-center whitespace-nowrap',
        'focus:outline-none focus-visible:ring-2 font-semibold',
        'transition duration-100 ease-in-out',
        'rounded-md',
        className,
      )}
    >
      {IconLeft && (
        <span className="self-center">
          <IconLeft className="h-5 w-5 text-inherit" />
        </span>
      )}
      {children && <div className="mx-3 flex justify-center">{children}</div>}
      {IconRight && (
        <span className="self-center">
          <IconRight className="h-5 w-5" />
        </span>
      )}
    </Component>
  );
};

export default ButtonBase;
