import clsx from 'clsx';
import { OptionComponentProps, Options } from '../DropDown/types';
import TextOption from '../DropDown/TextOption';
import { useRef, forwardRef, useEffect, useImperativeHandle } from 'react';
import { TFunction } from '../../../renderless/useTranslations';
import { useTranslations } from '../../../renderless/useTranslations';

export type OptionsListProps = {
  className?: string;
  onClose: () => void;
  options: Options;
  OptionComponent?: React.ComponentType<OptionComponentProps>;
  onSelectValue: (newValue: string) => void;
  value: string;
  hideEmptyOption?: boolean;
  large?: boolean;
};

const useContent = (t: TFunction) => ({
  noResults: t('searchableDropdown.noResults'),
});

const OptionsList = forwardRef<HTMLUListElement, OptionsListProps>(
  (
    {
      className,
      onClose,
      options,
      OptionComponent = TextOption,
      onSelectValue,
      value,
      hideEmptyOption,
      large,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslations();
    const content = useContent(t);
    const selectedRef = useRef<HTMLLIElement>(null);
    const listboxRef = useRef<HTMLUListElement>(null);

    //Magic for handling multiple forward refs
    useImperativeHandle(
      ref,
      () =>
        ({
          get selectedRef() {
            return selectedRef.current;
          },
          get listboxRef() {
            return listboxRef.current;
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any),
    );

    useEffect(() => {
      const target = selectedRef?.current;
      if (target && target.parentNode) {
        const parentNode = target.parentNode as HTMLElement;
        parentNode.scrollTop = target.offsetTop - target.offsetHeight;
      }
    }, [ref]);

    return hideEmptyOption && options.length === 0 ? null : (
      <ul
        data-testid="searchable-dropdown-options-list"
        id="dropdown-options"
        ref={listboxRef}
        tabIndex={-1}
        role="listbox"
        aria-activedescendant={value}
        className={clsx(
          className,
          large && 'w-full',
          'max-h-60 w-full overflow-y-scroll',
          'box-border rounded bg-white border-black border py-1 select-none focus:outline-none z-10',
        )}
        {...props}
      >
        {options.length > 0 ? (
          options.map((option) => (
            <OptionComponent
              key={option.value}
              onClick={() => onSelectValue(option.value)}
              selected={option.value === value}
              ref={option.value === value ? selectedRef : undefined}
              Icon={option.icon}
              {...option}
            />
          ))
        ) : (
          <OptionComponent
            onClick={onClose}
            selected={false}
            ref={undefined}
            label={content.noResults as string}
            value=""
          />
        )}
      </ul>
    );
  },
);

OptionsList.displayName = 'OptionList';

export default OptionsList;
