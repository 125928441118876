import React, { useState } from 'react';
import { DocResult, RiskIndicatorString, RiskIndicatorStringToPillType } from 'src/screens/types';
import { DocumentResultsInfo } from 'src/components';
import { RiskIndicatorType } from 'src/components/molecules/RiskIndicator/RiskIndicator';
import MultiPDFViewer from 'src/components/atoms/MultiPDFViewer';

export type Props = {
  document: DocResult;
};

const AuthenticityCheckResult = ({ document }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const indicators: RiskIndicatorType[] = [
    {
      name: 'Digital Tampering',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.digital_tampering || RiskIndicatorString.NA
      ],
    },
    {
      name: 'Text Validation',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.text_validation || RiskIndicatorString.NA
      ],
    },
    {
      name: 'Data Consistency',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.data_consistency || RiskIndicatorString.NA
      ],
    },
    {
      name: 'Software Integrity',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.software_integrity || RiskIndicatorString.NA
      ],
    },
    {
      name: 'Author Validation',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.author_validation || RiskIndicatorString.NA
      ],
    },
    {
      name: 'Embedded Layering',
      type: RiskIndicatorStringToPillType[
        document.fdt_summary?.embedded_layering || RiskIndicatorString.NA
      ],
    },
  ];

  return (
    <>
      <MultiPDFViewer
        documents={[{ path: document.path, name: 'original', pdf: true }]}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />

      <DocumentResultsInfo
        title="Authenticity Decision"
        decision={document.decision_results?.authenticity_check}
        bullets={['The document has been successfully processed by our analysis tech']}
        riskIndicators={indicators}
      />
    </>
  );
};

export default AuthenticityCheckResult;
