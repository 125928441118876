import { FunctionComponent } from 'react';

type CardProps = {
  title: string;
  subtitle: string;
};

const CardSnippet: FunctionComponent<CardProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col border border-gray-200 rounded-xl py-4 px-4 w-full hover:bg-gray-50">
      <p className="text-sm text-gray-500 font-thin">{title}</p>
      <p className="text-lg font-semibold">{subtitle}</p>
    </div>
  );
};

export default CardSnippet;
