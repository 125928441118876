import React from 'react';
import clsx from 'clsx';

export type Props = {
  percentageComplete: number;
  subStatusText: string;
  subStatusCompletedText?: string;
  statusText: string;
  className?: string;
  children?: React.ReactNode;
  splitText?: boolean;
};

const ProgressBar = ({
  className,
  percentageComplete,
  statusText,
  subStatusText,
  subStatusCompletedText,
  splitText,
  ...props
}: Props) => {
  return (
    <div {...props} data-testid="progressbar" className={clsx('flex flex-col', className)}>
      <label
        htmlFor="progressBar"
        className={clsx('w-full uppercase text-xs mb-1', splitText && 'flex justify-between')}
      >
        <span className={'text-grey-800'}>{statusText}</span>
        <span className={'text-grey-500 ml-1'}>
          {percentageComplete === 100 && subStatusCompletedText
            ? subStatusCompletedText
            : subStatusText}
        </span>
      </label>
      <div
        id="progressBar"
        role="progressbar"
        aria-valuenow={percentageComplete}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuetext={statusText}
        className="w-100 relative h-[8px] rounded-[4px] bg-grey-300"
      >
        <span
          className={clsx(
            'block absolute left-0 top-0 h-[8px] rounded-[4px]',
            'transition-[width] duration-100 ease-linear',
            percentageComplete === 100 || !subStatusCompletedText ? 'bg-blue-500' : 'bg-blue-300',
          )}
          style={{ width: percentageComplete + '%' }}
        ></span>
      </div>
    </div>
  );
};

export default ProgressBar;
