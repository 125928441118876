import React, { useState } from 'react';
import clsx from 'clsx';
import { RadioButton } from 'src/components';

type option = {
  label: string;
  value: string;
};

export type Props = {
  className?: string;
  disabled?: boolean;
  options: option[];
  value: string;
  onChange: (value: string) => void;
};

const RadioGroup = ({ className, options, disabled, value, onChange, ...props }: Props) => {
  const [currentValue, setCurrentValue] = useState(value);

  const onChangeHandler = (value: string) => {
    setCurrentValue(value);
    onChange(value);
  };
  return (
    <ul {...props} data-testid="radiogroup" role="radiogroup" className={clsx('', className)}>
      {options.map((option) => (
        <li key={option.value}>
          <RadioButton
            label={option.label}
            value={option.value}
            disabled={disabled}
            active={currentValue === option.value}
            onChange={(value) => onChangeHandler(value)}
          />
        </li>
      ))}
    </ul>
  );
};

export default RadioGroup;
