import clsx from 'clsx';
import DecisionPill from '../DecisionPill';
import { DecisionString } from 'src/screens/types';

type Props = {
  title: string;
  subtitle?: string;
  className?: string;
  decision?: DecisionString;
};

const SingleSection = ({ title, subtitle, className, decision }: Props) => {
  return (
    <div
      data-testid="single-section"
      className={clsx(!open && 'border-b lg:border-none', className)}
    >
      <div
        className="py-4 flex flex-row justify-between w-full text-left items-center bg-white relative z-10 group space-x-4"
      >
        <div className="absolute group-hover:bg-blue group-hover:bg-opacity-50 transition-all duration-300 rounded-md h-full w-[calc(100%+32px)] -left-4 z-0" />
        <div className="w-full z-10">
          <h4 className="text-xl font-medium">{title}</h4>
          <p className="text-grey-800">{subtitle}</p>
        </div>
        <div>
          { decision ? <DecisionPill decision={decision}/> : undefined }
        </div>
      </div>

    </div>
  );
};

export default SingleSection;
