import React from 'react';
import clsx from 'clsx';
import { FormTick } from '../../../icons';

export type Props = {
  className?: string;
  children?: React.ReactNode;
  active: boolean;
  disabled?: boolean;
  value: string;
  label: string;
  onClick: (value: string) => void;
};

const Checkbox = ({ className, active, value, label, disabled, onClick, ...props }: Props) => {
  const onClickHandler = (event: React.FormEvent<HTMLInputElement>) => {
    onClick(event.currentTarget.value);
  };

  return (
    <label
      htmlFor={value}
      className={clsx(
        'flex flex-row items-center rounded relative transition duration-100 ease-in-out mb-4 max-w-fit',
        disabled ? 'border-grey-500 cursor-default' : 'cursor-pointer',
        className,
      )}
      data-testid="checkbox"
      {...props}
    >
      <input
        id={value}
        type="checkbox"
        role="checkbox"
        value={value}
        checked={active}
        aria-checked={active}
        {...props}
        data-testid="radiobutton"
        className={clsx('block ml-1 focus:outline-none peer', className)}
        onChange={(e) => onClickHandler(e)}
        disabled={disabled}
      />
      <span
        className={clsx(
          'absolute left-0 h-5 w-5 rounded-full border block bg-white  transition duration-100 ease-in-out',
          active
            ? disabled
              ? 'border-grey-300 bg-grey-300 peer-focus:border-grey-300 peer-hover:border-grey-300'
              : 'bg-blue-600 border-blue-600'
            : disabled
            ? 'border-grey-500'
            : 'peer-focus:border-blue-700 peer-hover:border-blue-700',
        )}
      >
        {active && <FormTick className="absolute top-[0.3rem] left-1" />}
      </span>

      <span className={clsx('block ml-3', active && 'text-blue-600', disabled && 'text-grey-500')}>
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
