/* eslint-disable @next/next/no-img-element */
import React from 'react';
import clsx from 'clsx';

export type Props = {
  className?: string;
  images: string[];
  activeImageIndex: number;
  setActiveImageIndex: (number) => void;
};

const MultiImageViewer = ({
  className,
  images,
  activeImageIndex,
  setActiveImageIndex,
  ...props
}: Props) => {
  return (
    <div
      {...props}
      data-testid="multi-image-viewer"
      className={clsx('h-full lg:w-full flex flex-col', className)}
    >
      <img src={images[activeImageIndex]} className="w-sm lg:w-full lg:max-h-full" alt="" />
      {images.length > 1 && (
        <div className="w-full h-auto pb-2 lg:w-full flex flex-row space-x-2 mt-1 overflow-x-scroll">
          {images.map((i, ix) => (
            <button
              key={ix}
              className={clsx(
                'text-sm flex-none transition-all duration-100',
                ix === activeImageIndex && 'font-bold bg-gray-50 opacity-80',
              )}
              onClick={() => setActiveImageIndex(ix)}
            >
              <img className="w-20 h-20 flex-none" src={i} alt="" />
              <span>{ix + 1}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiImageViewer;
