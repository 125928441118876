import React, { useState } from 'react';
import { SearchableDropdown } from 'src/components';
import { Option } from 'src/components/molecules/DropDown/types';
import AddressOption from './AddressOption';
import {
  getAddress,
  getAddressList,
  PostcoderAddress,
  PostcoderAutocompleteResults,
} from './postcoderApi';
import clsx from 'clsx';
import { Search } from 'src/icons';

type ExtendedOption = Option & {
  count?: string;
};

type Props = {
  onSelectAddress: (address: PostcoderAddress) => void;
  label: string;
  placeholder: string;
  className?: string;
};

const UKAddressAutoComplete = ({ onSelectAddress, label, placeholder, className }: Props) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<ExtendedOption[]>([]);
  const [completedSearch, setCompletedSearch] = useState(false);
  const [selected, setSelected] = useState('');

  const handleSearch = async (text: string, pathfilter?: string) => {
    if (completedSearch) {
      setCompletedSearch(false);
    }
    const results = (await getAddressList({
      query: text,
      pathfilter,
    })) as PostcoderAutocompleteResults[];

    setOptions(
      results.map(({ id, count, summaryline, locationsummary }: PostcoderAutocompleteResults) => {
        if (count === 1) {
          return { label: `${summaryline} ${locationsummary}`, value: id };
        }
        return {
          label: `${summaryline} ${locationsummary}`,
          value: id,
          count: `${count} Addresses -->`,
        };
      }),
    );
  };

  const handleKeyboardSelect = (value: string) => {
    setSelected(value);
  };

  const handleSelectAddress = async (id: string) => {
    const addressSelected = options.find((option) => option.value === id);
    if (addressSelected?.count) {
      handleSearch(search, id);
    } else {
      const result = await getAddress(id, search);
      setCompletedSearch(true);
      onSelectAddress(result);
    }
  };

  const handleChange = (text: string) => {
    setSearch(text);
    if (text.length >= 3) {
      handleSearch(text);
    }
  };

  const handleClose = () => {
    setOptions([]);
    setSearch('');
    setSelected('');
  };

  return (
    <div className={clsx('w-full z-0', className)}>
      <SearchableDropdown
        label={label}
        large
        placeholder={placeholder}
        value={selected}
        options={options}
        onSelectValue={handleSelectAddress}
        onKeyboardSelect={handleKeyboardSelect}
        isNativeOnMobile={false}
        onSearch={handleChange}
        filter={(o) => o}
        OptionComponent={AddressOption}
        preventAutoClose={!completedSearch}
        preventIconRotation
        onClose={handleClose}
        hideEmptyOption
        IconComponent={Search}
      />
    </div>
  );
};

export default UKAddressAutoComplete;
