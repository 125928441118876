import { useEffect, createContext, ReactElement } from 'react';
import useAuth from 'src/renderless/useAuth';

export const AnalyticsContext = createContext<Record<string, unknown>>({});

const AnalyticsProvider = ({ children }: { children: ReactElement }) => {
  const { user } = useAuth();

  useEffect(() => {
    if ((window as any)?.smartlook && user.type === 'logged' && user.value) {
      (window as any).smartlook('identify', user.value?.uuid, {
        name: `${user.value?.first_name} ${user.value?.last_name}`,
        email: user.value?.email,
      });
    }
  }, [user]);

  return <AnalyticsContext.Provider value={{}}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
