import { useContext, createContext, useState, useEffect } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { TypeState, TypeAndValueState } from 'src/utils/types';
import { Spinner } from 'src/components';

const AxiosInstanceWithAuth = axios.create({
  withCredentials: true,
  timeout: 120_000,
});
const AxiosInstanceForRefresh = axios.create({
  withCredentials: true,
  timeout: 12_000,
});

type AuthValueType = {
  token?: string;
  tokenExpiry?: string;
  hasSessionLogin: boolean;
  user: User;
};

export type MeData = {
  first_name: string;
  last_name: string;
  uuid: string;
  wizardless_check_access: boolean;
  applicant_aml_access: boolean;
  account_type: string;
  email?: string;
};

const initialState: AuthValueType = {
  token: undefined,
  tokenExpiry: undefined,
  hasSessionLogin: false,
  user: { type: 'unspecified' },
};

export type User =
  | TypeState<'unspecified'>
  | TypeState<'anonymous'>
  | TypeAndValueState<'logged', MeData>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthContext = createContext<[AuthValueType, (value: any) => void]>([
  initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

const FullscreenSpinner = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <Spinner />
  </div>
);

const isTokenExpired = (tokenExpiry: string) =>
  !tokenExpiry || new Date(tokenExpiry).getTime() > Date.now();

const getUser = async (accessToken: string, user: User): Promise<User> => {
  try {
    if (accessToken) {
      if (user && user.type === 'logged') {
        return user;
      }
      const { data: userData } = await AxiosInstanceForRefresh.get<MeData>(
        process.env.NEXT_PUBLIC_DJANGO_API_V3_URI + '/me',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      return { type: 'logged', value: userData };
    }
    return { type: 'anonymous' };
  } catch (e) {
    return { type: 'anonymous' };
  }
};

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [value, setValue] = useState<AuthValueType>(initialState);

  const logout = () => {
    //todo call to clear session cookie too
    setValue({
      ...value,
      token: undefined,
      tokenExpiry: undefined,
      user: { type: 'anonymous' },
    });
  };

  const getCurrentToken = async () => {
    try {
      const { data } = await AxiosInstanceForRefresh.get(
        process.env.NEXT_PUBLIC_DJANGO_API_URI + '/token/refresh',
      );

      setValue({
        ...value,
        token: data.access,
        tokenExpiry: data.access_exp_time,
        user: await getUser(data.access, value.user),
      });
      return data.access;
    } catch (e) {
      logout();
    }
  };

  const getCurrentTokenIfNotExpired = async () => {
    if (isTokenExpired(value.tokenExpiry)) return getCurrentToken();
    return value.token;
  };

  useEffect(() => {
    getCurrentToken();
    AxiosInstanceWithAuth.interceptors.request.use(async (config) => {
      const token = await getCurrentTokenIfNotExpired();
      if (token && config.withCredentials) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Specifically needs to run once and only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={[value, setValue]}>
      {value.user.type === 'unspecified' ? <FullscreenSpinner /> : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const [value, setValue] = useContext(AuthContext);

  const loginWithUsernameAndPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_DJANGO_API_URI}/login/`,
        {
          email,
          password,
        },
        { withCredentials: true },
      );
      setValue({ ...value, hasSessionLogin: true });
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  type TokenApiResponse = {
    access: string;
    access_exp_time: string;
  };
  const loginAndGetTokenWithUsernameAndPassword = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await loginWithUsernameAndPassword({ email, password });
      const { access, access_exp_time } = await axios
        .post<TokenApiResponse>(
          `${process.env.NEXT_PUBLIC_DJANGO_API_URI}/token/user/`,
          {
            email,
            password,
          },
          { withCredentials: true },
        )
        .then(({ data }) => data);
      const user = await getUser(access, value.user);
      setValue({
        ...value,
        token: access,
        tokenExpiry: access_exp_time,
        user,
      });
      return user;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  const loginWithOTL = async (email: string, type: 'crm' | 'portal') => {
    try {
      const res = await axios
        .post<TokenApiResponse>(
          `${process.env.NEXT_PUBLIC_DJANGO_API_URI}/password/request-otl/`,
          {
            email,
            use_jwt: type === 'portal',
          },
          { withCredentials: true },
        )
        .then(({ data }) => data);
      return res;
    } catch (e) {
      Sentry.captureException(e);
      throw e;
    }
  };

  return {
    ...value,
    loginWithUsernameAndPassword,
    loginAndGetTokenWithUsernameAndPassword,
    loginWithOTL,
    axiosInstance: AxiosInstanceWithAuth,
  };
};

export default useAuth;
